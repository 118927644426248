.hero {
  display:flex;
  flex-direction: column;
  align-items: center;
  width:100%;
  max-width:1280px;
}

.hero-background {
  height:100px;
  width:100%;
  border-radius:10px;
  background-size:cover;
  background-position: center;
  background-repeat: no-repeat;
}

@media (min-width:1024px) {
  .hero-background {
    height:140px;
    width:50%;
  }
}

.picture {
  margin-top:-50px;
  width: 90px;
  height: 90px;
  border: 5px solid white;
  border-radius: 50%;
}

.name {
  font-size: 16px;
}

.description {
  font-weight: 400;
  font-size: 13px;
}
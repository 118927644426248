.overlay {
  position: fixed;
  top:0;
  left:0;
  background: rgba(0,0,0,0.8);
  width: 100%;
  height:100%;
  z-index:50;
  display:flex;
  align-items:center;
  justify-content:center;
}
.popup {
  max-width:1024px; 
  width:100%;
  max-height:800px;
  height: 100%;
  background:white;
  position: relative;
  border-radius:8px;
  padding: 30px 90px;
  overflow-y: scroll;
}

@media (max-width:1023px) {
  .popup {
    padding: 8px 10px;
    max-height: unset;
  }

}

.popup__image {
  width:100%;
  margin: 0 auto;
  height:200px;
  border-radius:8px;
  overflow:hidden;
  background-position: 0% 30%;
  background-size: cover;
  display: flex;
  align-items:center;
  position:relative;
}

@media (max-width:1023px) {
  .popup__image {
    margin-top: 40px;
  }
}


.popup__image::after {
  content: linear-gradient(transparent, rgba(0,0,0,0.6) 80%,rgba(0,0,0,0.9));
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.popup__close {
  position:absolute;
  top:10px;
  font-size:40px;
  line-height:30px;
  right:10px;
  cursor:pointer
}


.popup__titles{
  color:white;
  z-index:3;
  margin-left:300px;
}
.popup__title {
  font-size:35px;
  font-weight:800;
}

.popup__subtitle {
  font-size:16px;
}

.popup__description {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 30px;
}

.popup__text {
  display:flex;
  margin-top:30px;  
}

.popup__left {
  max-width:300px;
  width:100%;
  padding-right:20px;
}


.popup__tags {
  display:flex;
  flex-wrap:wrap;
}
.popup__tag {
  padding: 4px;
  font-size: 12px;
  margin: 5px;
  margin-left:0;
  border: 1px solid rgba(0,0,0,0.4);
  border-radius:4px;
}

.popup__left__card {
  margin-bottom: 30px;
}
.popup__left__infos {
  font-weight: 600;
}
.popup__subtitles {
  font-size:12px;
  font-weight:400;
  margin-top:10px;
}



@media (max-width:1023px) {
  .popup__text {
    flex-direction:column;
  }

  .popup__titles {
    margin-left: unset;
    padding: 20px;
  }
  .popup__left__card {
    margin-bottom: 20px;
  }
  .popup__left {
    padding-right:0;
    margin-bottom:30px;
  }
}

.popup__left__buttons {
  display: flex;
  flex-direction: column;

  align-items: flex-start;
}

.popup__left__button {
  margin-bottom: 10px;
  background:black;
  border-radius:4px;
  padding: 8px 16px;
  color:white;
  text-decoration: none;
}

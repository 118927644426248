.posts {
  width:100%;
  max-width: 1280px;
  display:grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 15px;
  grid-row-gap: 15px;
  margin:20px 0 110px 0;
}
@media (min-width:1024px) {
  .posts {
    grid-template-columns: repeat(4, 1fr);
    order:3;
  }
}

.tabs {
  background: #E1E1E1;
  border-radius:10px;
  overflow: hidden;
  margin: 20px 0;
  overflow-x:auto;
  display:flex;
  max-width:100%;
}

.tab {
  padding: 10px;
  border-radius:10px;
  font-size: 12px;
  font-weight: 500;
  transition: .3s ease-in-out;
  white-space:nowrap;
}

@media (min-width:1024px) {
  .tab {
    padding: 12px 16px;
  }
}

.tab--inactive:hover {
  background: #C6C6C6;
}
.tab--inactive {
  cursor: pointer;
  color: #818181
}

.tab--active {
  background: #000;
  color: white;
}
